.login-button {

}
  .login-button:hover {
    background-color: #BABABA;
    border: none; }

.sign-in-field {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.login-error {
  color: red;
  font-weight: 50;
  font-size: 75%;
}

@-webkit-keyframes autofill {
  to {
    color: #9e9e9e;
    background: transparent;
  }
}

@keyframes autofill {
  to {
    color: #9e9e9e;
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-name: autofill;
  animation-fill-mode: both;
  -webkit-animation-name: autofill;
  -webkit-animation-fill-mode: both;
}