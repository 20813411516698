.avatarContainer {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 8px;
}

.timelineEvent {
  font-weight: 500;
}

.timelineCaption {
  display: flex;
  flex-direction: row;
  font-size: 12px;
}

.timelineLink {
  color: #128dff;
  text-decoration: none;
  cursor: pointer;
}

.inspectionButton {
  color: #128dff;
  cursor: pointer;
  align-items: flex-start;
}

.feedbackModal {
  width: 480px;
  padding: 16px;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
}

.modalTitle {
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.24px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 32px;
}

.modalBody {
  margin-top: 16px;
  margin-bottom: 32px;
}

.toClick {
  cursor: pointer;
}

.createTaskButton {
  display: flex;
  height: 40px;
  width: 121px;
  justify-content: center;
  align-items: center;
  display: flex;
  padding: 16px;
  border-radius: 8px;
  background-color: #ffffff;
  border: none;
  color: #128dff;
  font-size: 16px;
  font-weight: 700;
  cursor: pointer;
}

.parkingField {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.subscript {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
}

.fieldValue {
  font-size: 16px;
  font-weight: 700;
}

.parkingImage {
  width: 240px;
  height: 240px;
  object-fit: cover;
  justify-content: center;
  align-items: center;
  margin-bottom: 32px;
}

.vehicleImageContainer {
  display: flex;
  flex-direction: row;
  margin-left: auto;
  margin-right: auto;
  justify-content: space-between;
  margin-bottom: 24px;
}

.previousContainer {
  display: flex;
  flex-direction: column;
}

.newContainer {
  display: flex;
  flex-direction: column;
}

.carChangeImg {
  width: 270px;
  height: 88px;
  margin: 16px 0;
}

.changeArrow {
  align-self: center;
}

.carPlate {
  font-size: 20px;
  font-weight: 700;
  text-align: center;
  color: #128dff;
  cursor: pointer;
}

.closeButton {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #414141;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.carChangeContainer {
  position: absolute;
  display: flex;
  flex-direction: column;
  background-color: #f2f2f2;
  min-width: 800px;
  max-width: 900px;
  padding: 32px;
  border-radius: 16px;
  border: none;
}

.carChangeContainer > h2 {
  margin-top: 0;
  font-weight: 700;
}

.linkButton {
  color: #128dff;
  text-decoration: none;
  cursor: pointer;
  margin-top: 4px;
}
