.container{
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: auto;
  margin: 48px;
  background-color: white;
}

.disabled {
  color: #87cefa;
}

.sendButton {
  height: 32px;
  width: 81px;
  color: #ffffff;
  border-radius: 20px;
  border: 1px solid #128dff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  font-weight: 700;
  font-size: 16px;
  margin: 0 48px 24px auto;
}

.buttonEnabled{
  background-color: #128dff;
}

.buttonDisabled{
  background-color: #87cefa;
  border-color: #87cefa;
}

.chatBox{
  display: flex;
  flex-direction: column;
  padding: 16px;
  resize: none;
  width: auto;
  border: none;
  background-color: #f2f2f2;
  border-radius: 6px;
  margin: 48px;
  &:focus {
    outline: none;
  }
  font-size: 16px;
}

.inputBox {
  height: 124px;
  padding: 16px;
  resize: none;
  width: auto;
  border: solid 2px #dedede;
  border-radius: 3px;
  margin: 0 48px 48px 48px;
  &:focus {
    outline: none;
  }
  font-size: 16px;
}

.message{
  border: 1px solid #fff;
  border-radius: 16px;
  margin: 8px 0;
  padding: 8px 10px;
  width: 70%;
  white-space: pre-wrap;
}

.messageContainerAi{
  display: flex;
  justify-content: start;
}

.messageContainerUser{
  display: flex;
  justify-content: end;
}

.fromAi{
  background-color: #dedede;
}

.fromUser{
  background-color: #fff;
}

.loadingMessage{
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}