@import "../../../../assets/styles/variables";

// .denyPopup {
//   width: 620px;
//   border: 1px solid #dedede;
//   border-radius: 16px;
//   background-color: #f2f2f2;
//   padding: 32px;
//   display: flex;
//   flex-direction: column;
// }

.denyPopup {
  width: 546px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.denyReason {
  padding: 0.7em;
  resize: none;
  height: 108px;
  border: 1px solid #dedede;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 32px;
}

.cancelButton {
  height: 40px;
  width: 86px;
  background-color: #ffffff;
  color: #414141;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.actionButtons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.saveButton {
  height: 40px;
  width: 86px;
  background-color: #ffffff;
  color: #128dff;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.notSaveButton {
  height: 40px;
  width: 86px;
  background-color: #ffffff;
  color: #87cefa;
  border-radius: 8px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.arrangePopup {
  width: 536px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.arrangeTitle {
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
}

.plainText {
  font-size: 16px;
}

.bold {
  font-weight: 700;
}

.dropdownText {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
}

.financeInfo {
  padding-bottom: 28px;
  border-bottom: solid 2px #dedede;
}

.installmentContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 214px;
  margin-top: 16px;
}

.dateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  width: 125px;
  border: 1px solid #414141;
  border-radius: 10px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 15px;
  height: 24px;
}

.elementContainer {
  width: 252px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-right: 12px;
  margin-bottom: 8px;
}

.errorModal {
  display: flex;
  padding: 32px;
  width: 536px;
  height: 91px;
  flex-direction: column;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 16px;
}

.text {
  width: 100%;
  display: flex;
  justify-content: center;
}

.errorCancel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorCancelButton {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #414141;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  font-weight: 700;
  font-size: 16px;
}

.importPopup {
  width: 400px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  display: flex;
  flex-direction: column;
  text-align: left;
}

.uploadContainer {
  display: flex;
  flex-direction: column;
}

.importTitle {
  font-weight: 600;
  font-size: 32px;
  margin-bottom: 12px;
}

.importSubtitle {
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 12px;
}

.importFileList {
  margin-top: 12px;
  font-weight: 500;
}

.currentTable {
  max-width: 1280px;
  // height: 399px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px 32px;
  display: flex;
  flex-direction: column;
  // position: relative;
}

.currentTableLarge {
  max-width: 1280px;
  // height: 399px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px 32px;
  display: flex;
  flex-direction: column;
  // position: absolute;
  margin-left: 64px;
}

.titles {
  display: flex;
  flex-direction: row;
  margin-bottom: 11px;
  font-size: 16px;
  padding: 0 16px;
  justify-content: space-between;
}

.title {
  width: 100px;
  font-weight: 600;
  // padding: 0 8px;
  word-wrap: break-word;
  text-align: center;
}

.dataTable {
  height: 300px;
  background-color: #ffffff;
  overflow-y: auto;
  border-radius: 5px;
  padding: 12px 0;
  :hover {
    background-color: rgba(18, 141, 255, 0.1);
  }
}

.dataRow {
  display: flex;
  flex-direction: row;
  padding: 10px 16px;
  justify-content: space-between;
}

.dataChunk {
  width: 100px;
  // padding: 0 8px;
  text-align: center;
  pointer-events: none;
}

.tableButtons {
  display: flex;
  justify-content: center;
  margin-top: 20px;
  justify-content: space-between;
}

.checkBox {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.checkBoxTitle {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.msgModal {
  display: flex;
  padding: 32px;
  width: 536px;
  flex-direction: column;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 16px;
}

.text {
  width: 100%;
  display: flex;
  justify-content: center;
}

.msgClose {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.msgCloseButton {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #414141;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  font-weight: 700;
  font-size: 16px;
}

.password {
  margin-bottom: 10px;
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.reason {
  display: flex;
  flex-direction: column;
  font-size: 12px;
}

.reasonContent {
  border-radius: 8px;
  border: 1px solid var(--Colors-Grey-04, #414141);
  background: var(--Colors-Grey-01, #FFF);
    display: flex;
  height: 4em;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}

.passwordContent {
  border-radius: 8px;
  border: 1px solid var(--Colors-Grey-04, #414141);
  background: var(--Colors-Grey-01, #FFF);
    display: flex;
  height: 1em;
  padding: 8px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 4px;
  align-self: stretch;
}
