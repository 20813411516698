.batchActionButtons {
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 220px;
  margin: 32px;
}

.actionButton {
  background-color: #fff;
  cursor: pointer;
  width: 100%;
  height: 34px;
  margin-bottom: 16px;
  font-weight: 400;
  border: 1px solid #414141;
  box-shadow: 0 4px 6px #00000052;
  color: #414141;
  border-radius: 26px;
  display: flex;
  align-items: center;
  padding: 0 1em;
}

.actionButtonIcon {
  margin-right: 5px;
  height: 40px;
  width: 40px;
}

.actionModal {
  display: flex;
  width: 800px;
  max-width: 800px;
  overflow-y: unset;
  border-radius: 16px;
  background-color: #414141;
  height: 526px;
  display: flex;
}

.actionModalLarge {
  display: flex;
  width: 800px;
  max-width: 800px;
  overflow-y: unset;
  border-radius: 16px;
  background-color: #414141;
  height: 532px;
  display: flex;
}

.selections {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 32px 16px 32px 32px;
  max-width: 300px;
  width: 300px;
}

.actionContainer {
  width: 500px;
  max-width: 500px;
  padding: 32px;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
}

.addtagAddButton {
  margin: 0 0 0 12px;
}

.manageCancel {
  position: relative;
  top: 40px;
}

.closeTags {
  position: relative;
  display: flex;
  justify-content: center;
  top: 23px;
}

.manageSaveButton {
  position: relative;
  left: 344px;
}

.createButtons {
  position: relative;
  top: 53px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.createButtonsExists {
  position: relative;
  top: 27px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.createSaveButton {
  position: relative;
}

.createCancelButton {
  position: relative;
}

.renameButtonsExists {
  position: relative;
  top: 28px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.renameButtons {
  position: relative;
  top: 54px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.renameSaveButton {
  position: relative;
}

.renameCancelButton {
  position: relative;
}

.deleteSaveButton {
  position: relative;
  left: 382px;
  top: -18px;
}

.deleteCancelButton {
  position: relative;
  top: 23px;
}

.mergeButtons {
  position: relative;
  top: 17px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.mergeSaveButton {
  position: relative;
}

.mergeCancelButton {
  position: relative;
}

.mergeOptions {
  display: flex;
  flex-direction: column;
  margin-top: 9px;
  width: 536px;
  height: 112px;
  font-size: 16px;
  font-weight: 400;
  overflow-y: auto;
  background-color: #ffffff;
  border-radius: 8px;
  padding: 12px;
}

.saveButton {
  position: relative;
  left: 286px;
}

.pendingSave {
  position: absolute;
  bottom: 0;
  left: 270px;
  display: flex;
  flex-direction: row;
}

.notesSaveButton {
  position: absolute;
  left: 283px;
  bottom: 0;
}

.individualSelection {
  justify-content: space-between;
  border-radius: 22px;
  min-height: 44px;
  margin-right: 12px;
  margin-bottom: 12px;
  padding: 0 16px 0 7px;
  display: flex;
  align-items: center;
  background-color: #fff;
}

.identifier {
  margin-left: 24px;
  font-weight: 600;
}

.contentContainer {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  margin-top: 32px;
  height: 398px;

  &__addNote {
    height: 398px;
  }

  &__changeStatus {
    height: 400px;
  }

  > h4 {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
    margin-bottom: 4px;
    margin-top: 0px;
  }
}

.optionContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.optionBox {
  margin-right: 3em;
  font-size: 16px;
  font-weight: 700;
}

.miniActionContainer {
  position: relative;
  margin-top: 12px;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;
}

.addTagContainer {
  display: flex;
  flex-direction: row;
  margin: 0 0 0.5em 0;
}

.iconButton {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #414141;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: white;
  font-size: 16px;
}

.iconButtonBlack {
  width: 34px;
  height: 34px;
  border-radius: 50%;
  border: 1px solid #414141;
  margin-left: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  background-color: rgb(65, 65, 65);
  color: #ffffff;
  margin-left: 10px;
  font-size: 16px;
}

.reminderContainer {
  display: flex;
  align-items: center;
  font-size: 16px;
  margin-right: 12px;
}

.redBorder {
  border-color: red;
}

.dateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  max-width: 100%;
  border: 1px solid #414141;
  border-radius: 10px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 15px;
  height: 24px;
  margin-left: 18px;
}

.dateInputRed {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  max-width: 100%;
  border: 1px solid #414141;
  border-radius: 10px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 15px;
  height: 20px;
  margin-left: 10px;
  border-color: red;
}

.selectionsBody {
  width: 100%;
  display: flex;
  flex-direction: column;
  height: 320px;
  overflow-y: auto;
}

.selectionsBodyStatus {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  top: 0px;
  height: 320px;
  overflow-y: auto;
}

.header {
  width: 100%;
  font-size: 28px;
  font-weight: 700;
  color: #fff;
}

.rightHeader {
  width: 100%;
  height: 462px;
}

.darkText {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 28px;
  font-weight: 700;
  color: #414141;
}

.textBoxContainer {
  position: relative;
}

.textBox {
  padding: 0.6em;
  width: 100%;
  height: 125px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  resize: none;
  font-size: 16px;
  font-weight: 400;
}

.textBoxRed {
  padding: 0.6em;
  width: 100%;
  height: 125px;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  border-color: red;
  resize: none;
}
.comment {
  position: relative;
  margin-top: 36px;
  width: 100%;
  > h4 {
    font-size: 16px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 8px;
  }
}
.commentBox {
  height: 112px;
}

.actionList {
  position: absolute;
  bottom: 236px;
}

.datepicker {
  position: relative;
  left: 15px;
  display: flex;
  flex-direction: row;
}

.chevronDown {
  position: relative;
  right: 23px;
  top: 2px;
}

.statusMessage {
  width: 630px;
  height: 202px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 23px 50px 22px 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.messageLine {
  margin-bottom: 20px;
}

.messageButton {
  position: relative;
  top: 18px;
}

.tagBox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  resize: none;
  padding: 0.6em;
  width: 436px;
  border-radius: 16px;
  height: 101px;
  overflow-y: auto;
}

.tagBoxUnder {
  display: flex;
  align-content: flex-start;
  flex-wrap: wrap;
  flex-direction: row;
  background: #ffffff;
  border: 1px solid #dedede;
  box-sizing: border-box;
  border-radius: 4px;
  resize: none;
  padding: 0.6em;
  width: 436px;
  border-radius: 16px;
  height: 101px;
  margin-bottom: 32px;
  overflow-y: auto;
}

//::-webkit-scrollbar {
//  width: 12px;
//}
// ::-webkit-scrollbar-track {
//   background: gray;
//   opacity: 0.5;
//   border-radius: 6px;
// }
// ::-webkit-scrollbar-thumb {
//   background: #ffffff;
//   border-radius: 6px;
//   height: 60px;
// }

.tagRed {
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2px 9px 2px 9px;
  border-radius: 12px;
  border: 1px solid red;
  height: 23px;
  width: fit-content;
  margin: 0 0.25em 0.5em;
}

.notapplicable {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 12px;
  width: 124px;
  height: 24px;
  font-size: 16px;
  font-weight: 400;
  color: #414141;
  margin: 17px auto 0;
  border: 1px solid #414141;
  background-color: #ffffff;
  padding: 1px 8px 0;
}

.tag {
  display: flex;
  flex: none;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  padding: 2px 9px 2px 9px;
  border-radius: 12px;
  border: 1px solid #414141;
  height: 23px;
  width: fit-content;
  margin: 0 0.25em 0.5em;
}

.times {
  cursor: pointer;
  font-size: 17px;
  margin-left: 12px;
}

.searchContainer {
  display: flex;
  flex-direction: row;
}

.autocompleteOpen {
  width: 344px;
  height: fit-content;
  border: none;
  border-radius: 17px 17px 0px 0px;
  background-color: #ffffff;
  margin: 0;
  padding: 6px 0 6px 10px;
  font-size: 16px;
}
.autocompleteClose {
  width: 344px;
  height: fit-content;
  border: none;
  border-radius: 17px;
  background-color: #ffffff;
  margin: 0;
  padding: 6px 0 6px 10px;
  font-size: 16px;
}

.autocompleteExtend {
  position: absolute;
  width: 344px;
  height: fit-content;
  border: none;
  border-radius: 0 0 17px 17px;
  background-color: #ffffff;
  margin: 0;
  padding: 6px 0;
  font-size: 16px;
  list-style-type: none;
  z-index: 9;
  box-shadow: rgba(0, 0, 0, 0.24) 0px 2px 2px;
}

.options {
  display: block;
  list-style: none;
  width: 30rem;
  transition: width 0.3s;
  margin: auto;
  position: relative;
  > li {
    display: block;
    background: white;
    margin: 10px auto;
    padding: 10px;
    font-size: 1.2rem;
    width: 100%;
    border-radius: 2px;
    :hover {
      font-weight: bold;
      color: #00b4cc;
      cursor: pointer;
      transition: 0.3s all;
    }
  }
}

.optionactive {
  background: whitesmoke;
  font-size: 1.5rem;
  color: #00b4cc;
}

.nooptions {
  color: white;
}

.inputBox {
  border: #ffffff;
  outline: #ffffff;
  font-size: 17px;
  padding-left: 10px;
  width: 300px;
}
.inputBoxLong {
  border: #ffffff;
  outline: #ffffff;
  font-size: 17px;
  padding-left: 10px;
  width: 830px;
}
.inputBoxCreate {
  border: #ffffff;
  outline: #ffffff;
  font-size: 16px;
  padding-left: 10px;
  width: 500px;
}

.autocompleteNotMatch {
  font-weight: 400;
  color: #a9a9a9;
  padding-bottom: 7px;
  padding-left: 37px;
  cursor: pointer;
}

.autocompleteActive {
  background-color: #f2f2f2;
  // padding-top: 3px;
  width: 344px;
  padding-bottom: 5px;
  padding-left: 37px;
  cursor: pointer;
}

.autocompleteMatch {
  font-weight: 700;
  color: #414141;
}

.addButton {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 16px;
}

.already {
  position: absolute;
  right: 0;
  top: 186px;
  font-size: 15px;
  color: red;
}

.renameSubTitle {
  position: relative;
  font-size: 16px;
  color: #414141;
  margin-bottom: 9px;
}

.tagAlreadyExists {
  position: relative;
  font-size: 16px;
  color: #ff0000;
  margin-left: 22px;
  margin-top: 8px;
}

.tagAlreadyExistsRename {
  position: relative;
  font-size: 16px;
  color: #ff0000;
  margin-left: 22px;
  margin-top: 9px;
}

.tagNotExists {
  display: none;
  position: relative;
  font-size: 16px;
  color: #ff0000;
  margin-left: 22px;
  margin-top: 8px;
}

.manageTags {
  width: 929px;
  height: 722px;
  display: flex;
  overflow-y: unset;
  border-radius: 16px;
  background-color: #f2f2f2;
}

.manageContainer {
  display: flex;
  width: 100%;
  margin: 32px;
  flex-direction: column;
  > h4 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.manageSearchBox {
  width: 865px;
  height: 34px;
  border: none;
  border-radius: 17px;
  background-color: #ffffff;
  margin: 0;
  padding: 5px 0 5px 10px;
  font-size: 16px;
}

.createInputBox {
  width: 536px;
  height: 32px;
  border: none;
  border-radius: 16px;
  background-color: #ffffff;
  margin: 0;
  padding: 5px 0 5px 12px;
  font-size: 16px;
}

#input-field {
  color: #414141;
}
input::placeholder {
  opacity: 0.5;
}

textarea::placeholder {
  font-size: 16px;
  font-weight: 400;
}

.alltags {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 461px;
  border: 1px solid #dedede;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: 30px;

  font-size: 18px;
  font-weight: 400;
  overflow-y: scroll;
  padding-bottom: 2px;
  padding-top: 3px;
  :hover {
    background-color: rgba(18, 141, 255, 0.1);
  }
}

.manageAllButton {
  display: flex;
  justify-content: left;
  align-items: center;
  font-size: 16px;
}

.tagContainer {
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
}

.tagContainerSelected {
  display: flex;
  width: 100%;
  position: relative;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  border-radius: 8px;
  background-color: rgba(18, 141, 255, 0.1);
  z-index: 1;
}

.editButtonSelected {
  display: flex;
  position: absolute;
  font-size: 20px;
  right: 39px;
}

.editButton {
  display: none;
}

.checkBoxSelected {
  display: flex;
  position: relative;
  font-size: 20px;
  margin-left: 22px;
}

.checkBox {
  display: none;
}

.editPen {
  color: #128dff;
  margin-right: 10px;
  cursor: pointer;
}

.editCircle {
  color: #ffffff;
  margin-right: 30px;
}

.editDelete {
  color: #424242;
  margin-right: 32px;
  cursor: pointer;
}

.taglistNoCheckBox {
  display: flex;
  padding-left: 62px;
  margin: 18px 0;
}

.taglist {
  display: flex;
  padding-left: 25px;
  margin: 18px 0;
}

.tagDeleted {
  padding-left: 20px;
  margin: 0.5em 0;
  color: #ff0000;
  text-decoration: line-through;
}

.tagBold {
  padding-left: 20px;
  margin: 0.5em 0;
  font-weight: bold;
}

.editTag {
  width: 600px;
  height: 253px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.editTitle {
  > h4 {
    font-size: 28px;
    font-weight: 700;
    margin: 0 0 32px 0;
  }
}

.nameContainer {
  height: 51px;
  display: flex;
  flex-direction: row;
  font-size: 16px;
  font-weight: 700;
}

.nameBox {
  width: 50%;
  display: flex;
  flex-direction: column;
  font-size: 16px;
  font-weight: 700;
}

.beforeName {
  padding-top: 12px;
}

.afterName {
  width: 100%;
  height: 24px;
  border: 1px solid #dedede;
  border-radius: 4px;
  background-color: #ffffff;
  font-size: 16px;
  margin-top: 9px;
}

.deleteTag {
  width: 600px;
  height: 224px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  display: flex;
  flex-direction: column;
  > h4 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 23px;
  }
}

.message {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  white-space: pre-wrap;
}

.faplusIcon {
  color: #ffffff;
  font-size: 16px;
  margin-right: 7px;
}

.titleButton {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  > h4 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.createNewTags {
  width: 600px;
  height: 255px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  > h4 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.renameTag {
  width: 600px;
  height: 278px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  > h4 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 26px;
  }
}

.mergeTag {
  width: 600px;
  height: 326px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  > h4 {
    font-size: 28px;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 32px;
  }
}

.mergeSubtitle {
  font-size: 16px;
  font-weight: 400;
}

.choice {
  margin-bottom: 12px;
  display: flex;
  white-space: pre-wrap;
  > input[type="radio"] {
    width: 20px;
    height: 20px;
  }
  align-items: center;
}

.ticketActionContainer {
  width: 700px;
  max-width: 700px;
  border-radius: 16px;
  background-color: #f2f2f2;
  height: 300px;
  display: flex;
  padding: 32px;
  flex-direction: column;
}

.ticketActionContainerSmall {
  width: 400px;
  border-radius: 16px;
  background-color: #f2f2f2;
  height: 173px;
  display: flex;
  padding: 32px;
  flex-direction: column;
}

.actionHeader {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 24px;
}

.ticketTextBox {
  resize: none;
  height: 120px;
  margin-top: 10px;
  padding: 6px 8px;
  border-radius: 5px;
  margin-bottom: 24px;
}

.ticketModalButtons {
  position: relative;
  bottom: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 36px;
}

.ticketModalCancelButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.ticketModalApplyButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 20px;
  width: 96px;
  height: 32px;
  background: #ffffff;
  border-radius: 20px;
  color: #128dff;
  border: none;
  font-weight: 600;
  cursor: pointer;
}

.timeUserContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;
}

.timeContainer {
  display: flex;
  flex-direction: column;
}

.reminderDateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  width: 208px;
  height: 30px;
  border: 1px solid #414141;
  border-radius: 14px;
  padding: 0 8px 0 16px;
  font-weight: 400;
  font-size: 15px;
  margin-top: 8px;
}

.calendarIcon {
  position: relative;
  right: 32px;
}

.searchAddContainer {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.addAgentButton {
  margin-left: 60px;
  margin-top: 4px;
}

.ticketTagsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.ticketTagBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 2px 12px;
  border-radius: 14px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 1px #f2f2f2 solid;
  background-color: rgba(218, 237, 255, 1);
  font-weight: 550;
  margin-right: 10px;
  margin-bottom: 6px;
}

.allSelection {
  align-items: center;
  font-size: 24px;
  color: #ffffff;
  font-weight: 600;
}

.tagInput {
  width: 210px;
  height: 25px;
  border-radius: 12px;
  border: 1px solid #414141;
  padding: 2px 6px;
  font-size: 16px;
  margin-right: 16px;
}

.addTagContent {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  margin-bottom: 12px;
}
