.editBox{
  width: 124px;
  height: 24px;
  border: 1px solid #DEDEDE;
  box-sizing: border-box;
  border-radius: 4px;
  text-align: center;
  font-size: 16px;
  margin-top: 8px;
  &:focus{
    border-color: #128dff;
    outline: none;
  }
}

.markEdited{
  border: 1px solid #414141
}

.markError{
  border: 1px solid red
}