.container {
  display: flex;
  flex-direction: column;
  padding-top: 20px;
  color: #414141;
}

.containerSmall {
  display: flex;
  flex-direction: column;
  padding: 0 0 0 32px;
  min-height: 100vh;
  color: #414141;
  min-width: 900px;
}

.containerPopup {
  display: flex;
  flex-direction: column;
  color: #414141;
}

.link {
  justify-content: center;
  text-decoration: none;
  color: black;
  :hover {
    background-color: #dedede;
    transition: 0.3s;
  }
}

.table {
  min-width: calc(100% - 60px);
  width: max-content;
  padding: 0 32px;
}

.tableSmall {
  width: max-content;
  min-width: 100%;
}

.tableContainer {
  overflow-x: auto;
  width: 100%;
}

.tableContainerPopup {
  overflow-x: auto;
  width: 100%;
  height: 455px;
  overflow: auto;
}

.tableCell {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: 120px;
}

.tableCellFlex {
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  width: fit-content;
}

.notClick {
  pointer-events: none;
  cursor: default;
}

.disabledLink {
  justify-content: center;
  text-decoration: none;
  color: black;
  :hover {
    background-color: #dedede;
    transition: 0.3s;
  }
  cursor: pointer;
}
