.filterRow {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 30px 0;
}

.line {
  width: 100%;
  border-bottom: 1px solid black;
}

.lineContainer {
  width: 100%;
  display: flex;
  align-items: center;
  height: 0;
}

.middleText {
  margin: 0 16px;
}
.actions {
  display: flex;
}

.dropdownLabel {
  font-size: 16px;
  font-weight: 600;
}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  // height: 100%;
  width: auto;
  margin-right: 40px;
}

.red {
  color: red;
}

.icon {
  font-size: 18px;
}

.blue {
  color: #128dff;
}

.actionButton {
  display: flex;
  height: 32px;
  width: 32px;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.textInput {
  &Field {
    display: flex;
    background: white;
    color: #414141;
    position: absolute;
    max-width: 100%;
    width: 124px;
    margin: 8px auto;
    border: 1px solid#414141;
    border-radius: 12px;
    padding: 0 8px;
    font-weight: 400;
    font-size: 16px;
    height: 24px;
    &:focus {
      border-color: #128dff;
      outline: none;
    }
  }
}

.dateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  max-width: 116%;
  width: 150px;
  margin: 8px auto 0;
  border: 1px solid #414141;
  border-radius: 12px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 16px;
  height: 24px;
}

.marginLeft {
  margin-left: 6px;
}
