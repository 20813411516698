.dropdownLabel {
  font-size: 16px;
  font-weight: 400;
}

.dropdown {
  display: flex;
  flex-direction: column;
}

.dropdownHeader {
  color: #414141;
  border: 1px solid #a0a0a0;
  border-radius: 8px;
  padding: 2px 6px;
  font-size: 16px;
  font-weight: 400;
  height: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.dropdownOption {
  position: absolute;
  color: #414141;
  border-color: #f2f2f2;
  border-style: solid;
  border-width: 1px;
  border-radius: 8px;
  padding-top: 5px;
  padding-bottom: 5px;
  font-size: 16px;
  font-weight: 300;
  cursor: pointer;
  overflow: hidden;
  z-index: 100;
  background-color: #fdfdfd;
  :hover {
    background-color: rgba(61, 161, 253, 0.1);
  }
}
