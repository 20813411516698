.statusCircle {
  width: 12px;
  height: 12px;
  border-radius: 6px;
}

.expandableList {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.textBubble {
  overflow-x: hidden;
  max-width: 120px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.extraBubbleCount {
  font-size: 12px;
  width: 18px;
  color: #128dff;
  text-align: right;
}
.bubbleTextContainer {
  display: flex;
  align-items: center;
  border-radius: 12px;
  padding: 5px 12px;
  height: 13px;
  background-color: #f2f2f2;
  max-width: 120px;
}

.expandableListItem {
  &:not(:first-of-type) {
    margin-top: 8px;
  }
  display: flex;
  align-items: center;
}

.statusValue {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.statusText {
  margin-left: 4px;
}
.textValue {
  overflow-wrap: anywhere;
  text-align: center;
}

.subtext {
  color: #bebebe;
  text-align: center;
}

.imageValue {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  max-width: 111px;
  max-height: 46px;
}

.statusMain {
  display: flex;
  align-items: center;
  justify-content: center;
}

.userImage {
  width: 100%;
}

.userImageContainer {
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px #dedede;
}

.userNameLink {
  margin-left: 8px;
  color: #128dff;
}

.userName {
  margin-left: 8px;
}

.userContainer {
  display: flex;
  align-items: flex-start;
}

.arrayCell {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  border: 1px solid white;
  background-color: #414141;
  color: white;
  margin: 1px;
  font-size: 14px;
  padding: 3px 8px;
  border-radius: 26px;
}

.ticketTag {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 0 12px;
  border-radius: 12px;
  flex: none;
  order: 1;
  flex-grow: 0;
  font-size: 12px;
  font-weight: 700;
  margin-left: 16px;
}
