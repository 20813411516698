.dropdown {
  position: absolute;
  background-color: white;
  border: 1px solid #e0e0e0;
  border-radius: 3px;
  transition: 0.2s;
  width: 8em;
  left: 60px;
  bottom: 70px;
  cursor: pointer;
  -webkit-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
  -moz-box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
  box-shadow: 0px 0px 10px -2px rgba(0, 0, 0, 0.75);
}

.dropdown:hover {
  background-color: #f2f2f2;
}

.visible {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #414141;
}

.invisible {
  display: none;
}

.icon {
  color:#ffffff;
  max-width: 60px;
}