.documentsPageBody {
  padding: 20px 32px;
  background-color: #f2f2f2;
  color: #414141;
  background-color: white;
  min-height: calc(100vh - 140px);
  padding: 0 32px;
  width: fit-content;
  height: fit-content;
  padding-bottom: 32px;
}

.documentsCanvas {
  position: relative;
  border-radius: 20px;
  border: 1px solid #dedede;
  margin: auto;
  padding: 32px;
  flex-direction: row;
  display: flex;
}

.carousel {
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 32px 32px;
  justify-content: center;
  height: 76px;
}

.current {
  position: absolute;
  right: 50%;
  transform: translate(50%, 0);
  display: flex;
  align-items: center;
  flex-direction: row;
}

.carouselHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.headerName {
  font-size: 24px;
  font-weight: 700;
}

.headerTime {
  opacity: 0.5;
}

.leftNav {
  margin-right: 40px;
  cursor: pointer;
}

.rightNav {
  margin-left: 40px;
  cursor: pointer;
}

.manualEntryButton {
  position: absolute;
  right: 0;
  background-color: white;
  color: #128dff;
  border-radius: 20px;
  font-size: 16px;
  padding: 6px 20px;
  width: max-content;
  height: 34px;
  border: 1px solid #128dff;
  font-weight: 700;
  cursor: pointer;
}

.manualEntryContainer {
  width: 480px;
  height: 720px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.title {
  font-size: 32px;
  font-weight: 700;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.subTitle {
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 16px;
}

.field {
  display: flex;
  flex-direction: column;
  margin-bottom: 12px;
}

.subscript {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 4px;
}

.inputField {
  height: 24px;
  padding: 4px 6px;
  border-radius: 4px;
  border: 1px solid #414141;
  font-size: 16px;
}

.datepickerContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.dateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  width: 450px;
  border: 1px solid #414141;
  border-radius: 14px;
  padding: 0 16px 0 12px;
  font-weight: 400;
  font-size: 15px;
  height: 32px;
}

.dateInputHalf {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  width: 201px;
  border: 1px solid #414141;
  border-radius: 14px;
  padding: 0 16px 0 12px;
  font-weight: 400;
  font-size: 15px;
  height: 32px;
}

.calendarIcon {
  position: relative;
  right: 40px;
}

.twoInOneRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.imageUploader {
  width: 100px;
  height: 100px;
  object-fit: contain;
  cursor: pointer;
}

.buttonsFooter {
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.timesIcon {
  border-color: #414141;
  background-color: #414141;
  color: white;
  border-radius: 50%;
  width: 18px;
  height: 18px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  bottom: 110px;
  left: 90px;
}

.documentsContent {
  padding-right: 30px;
  border-right: 1px solid #dedede;
}

.documentImages {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.documentImageProfile {
  width: 250px;
  height: 250px;
  border-radius: 50%;
  object-fit: cover;
}

.documentImageDriver {
  object-fit: cover;
  border-radius: 20px;
  width: 250px;
  height: 250px;
}

.documentImageName {
  text-align: center;
  font-weight: 700;
  font-size: 18px;
}

.rejectedText {
  opacity: 0.5;
  text-align: center;
}

.column {
  flex-direction: column;
}

.fullDocumentImage {
  max-width: 1000px;
  max-height: 1000px;
}

.documentDetailContainer {
  display: flex;
  flex-direction: row;
  padding: 20px;
  margin-top: 20px;
}

.detailOneThird {
  width: 30%;
  padding: 10px;
}

.detailsField {
  margin-bottom: 16px;
}

.detailField {
  font-weight: 500;
  height: 20px;
}

.commentsContainer {
  width: 350px;
  padding-left: 32px;
}

.commentsDisplay {
  padding: 20px 0;
  display: flex;
  flex-direction: column;
  height: 500px;
  overflow-y: auto;
}

.commentsDisplay::-webkit-scrollbar {
  display: none;
}

.commentContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.commentLeft {
  width: 240px;
}

.commentRight {
  opacity: 0.5;
  font-size: 12px;
}

.commentHeader {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.commentBody {
  margin-top: 6px;
}

.mediumFont {
  font-weight: 500;
}

.addButton {
  position: absolute;
  bottom: 35px;
  right: 32px;
}

.commentUserImage {
  width: 100%;
}

.imageContainer {
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  display: flex;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px #dedede;
  margin-right: 8px;
}

.addComments {
  position: absolute;
  bottom: 80px;
}

.editTextBoxInput {
  padding: 10px;
  resize: none;
  width: 328px;
  height: 60px;
  border: 2px solid #dedede;
  border-radius: 10px;
  font-size: 17px;
  margin-top: 12px;
}

.applyButton {
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  width: 96px;
  padding: 0.36em 1em;
  cursor: pointer;
  opacity: 1;
  color:#128dff;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.cancelButton {
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  width: 96px;
  padding: 0.36em 1em;
  cursor: pointer;
  opacity: 1;
  color:#414141;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.confirmButton {
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  width: 96px;
  padding: 0.36em 1em;
  cursor: pointer;
  opacity: 1;
  color:#ffffff;
  background-color: #128dff;
  display: flex;
  justify-content: center;
  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.confirmDeleteButton{
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  width: 96px;
  padding: 0.36em 1em;
  cursor: pointer;
  opacity: 1;
  color:#ff0000;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.userTagsContainer {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}

.tagCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 2px 12px;
  border-radius: 14px;
  flex: none;
  order: 1;
  flex-grow: 0;
  border: 1px #f2f2f2 solid;
  background-color: #f2f2f2;
  font-weight: 500;
  margin-right: 12px;
  margin-bottom: 8px;
}

.personalVehiclesContainer {
  display: flex;
  flex-wrap: wrap;
}

.personalVehiclesCell {
  width: calc(50% - 64px);
  box-sizing: border-box;
  padding: 16px;
  margin: 16px;
  border-radius: 8px;
  border: 1px solid #dedede;
}

.personalVehicleTitle{
  font-size: 20px;
  font-weight: 500;
}

.personalVehiclesFrameHolder {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.personalVehiclesFrame {
  width: 50%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
}

.editTags {
  position: absolute;
  right: 5%;
  background-color: white;
  color: #128dff;
  border-radius: 20px;
  font-size: 16px;
  padding: 6px 20px;
  width: max-content;
  border: 1px solid #128dff;
  font-weight: 700;
  cursor: pointer;
  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.tagsModalContainer {
  width: 480px;
  height: 700px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.tagsModalContainer .existingTagsFrame {
  display: flex;
  padding: 6px 8px;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  flex: 1 0 0;
  align-self: stretch;
  border: 1px solid #414141;
  border-radius: 4px;
  background: #FFF;
  margin-bottom: 60px;
  overflow-y: scroll;
}

.addTags {
  display: flex;
  align-items: flex-start;
  gap: 8px;
  align-self: stretch;
  justify-content: space-between;
  margin-bottom: 20px;
}

.searchBar {
  height: auto;
  width: 93%;
  padding: 8px 20px;
  align-items: center;
  align-self: stretch;
  border-radius: 20px;
  border: 1px solid #414141;
  background: #FFF;
  margin-bottom: 32px;
}

.addTags .addButton {
  position: inherit;
  display: flex;
  padding: 7.5px 20px;
  justify-content: center;
  font-size: 16px;
  font-weight: bold;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: #128dff;
  cursor: pointer;
  color: #ffffff;

  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.editTagsHead {
  color: #414141;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.editTagsHead .manageButton {
  position: inherit;
  display: flex;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: #ffffff;
  cursor: pointer;

  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.editTagsHead .createButton {
  position: inherit;
  display: flex;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 24px;
  background: #128dff;
  color: #ffffff;
  cursor: pointer;


  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.existingTagsHead {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.errorMessage {
  color: red;
  margin-bottom: 12px;
  font-size: 12px;
  margin-bottom: 4px;
  font-weight: 400;
}

/* .tags-modal-container {
  width: 360px;
  height: 500px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
} */

.allTags {
  display: flex;
  flex-direction: column;
  width: auto;
  height: 441px;
  border: 1px solid #dedede;
  border-radius: 8px;
  background-color: #ffffff;
  margin-top: -20px;
  
  font-size: 18px;
  font-weight: 400;
  overflow-y: scroll;
  padding-bottom: 2px;
  padding-top: 3px;
  :hover {
    background-color: rgba(18, 141, 255, 0.1);
  }
}

.allTags .tagContainer {
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  min-height: 40px;
  cursor: pointer;
  padding: 16px;
  margin-bottom: 8px;
}

.allTags .tagContainerSelected {
  display: flex;
  width: auto;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 8px;
  min-height: 40px;
  font-weight: bolder;
  background-color: rgba(18, 141, 255, 0.1);
  z-index: 1;
  cursor: pointer;
  padding: 16px;
  margin-bottom: 8px;
  
}

.manageTagsModalContainer {
  width: 500px;
  height: 600px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  flex-direction: column;
  overflow-y: scroll;
}

.tagTrash {
  border: 1px #414141;
  border-radius: 50%;
  background: #ffffff;
  display: flex;
  align-items: center;
  height: auto;
  width: auto;
  padding: 6px 6px;
  flex: none;
  z-index: 1;
  flex-grow: 0;

}

.manageTagsModalContainer .closeManageButton{
  display: flex;
  width: 100px;
  padding: 7.5px 20px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 16px;
  background: #ffffff;
  cursor: pointer;
  margin-top: 12px;

  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.popupModalContainer{
  width: 600px;
  height: 244px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;}

.deleteTagsMessage{
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  white-space: pre-wrap;
}

.rowCenter{
  display: flex;
  justify-content: center;
}