.actionContainer {
  width: 440px;
  padding: 32px;
  max-height: 380px;
  border-radius: 16px;
  background-color: #F2F2F2;
  display: flex;
  flex-direction: column;
}

.switchVehiclesContainer{
  width: 640px;
  max-height: 700px;
}

.vehiclesContainer{
  display: flex;
  justify-content: space-between;
  margin-bottom: 32px;
}

.vehiclePreviewItem{
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 40%;
  img {
    width: 320px;
    height: 140px;
    object-fit: contain;
  }
  p {
    margin: 0;
    font-size: 11px;
  }
}

.vehicleImage{
    img {
      width: 117px;
      height: 48px;
      object-fit: contain;
    }
    p {
      margin: 0;
      font-size: 11px;
    }
}

.bold{
    font-weight: 600;
}

.arrowRight{
  display: flex;
  justify-content: center;
  align-items: center;
}

.timeContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 24px;
}

.title{
  font-size: 32px;
  font-weight: 600;
  margin-bottom: 24px;
}

.text{
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 24px;
}

.time{

}

.dropdown {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  // height: 100%;
  margin-right: 40px;
}

.commentContainer{

}

.commentBox {
  resize: none;
  width: 100%;
  height: 120px;
  margin-top: 10px;
  padding: 6px 8px;
  border-radius: 5px;
  margin-bottom: 24px;
  box-sizing: border-box;
}

.relateUserButtons {
  position: relative;
  bottom: 0;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-top: 36px;
}

.button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    width: 96px;
    height: 32px;
    background: #ffffff;
    border-radius: 20px;
    border: none;
    font-weight: 600;
    cursor: pointer;
}
.cancel{}

.save{
  color: #128dff;
}

.disabled{
  color: #87cefa;
}

.subscript {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: -5px;
}

.searchContainer{
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 15px;
  align-items: center;
}

.searchBarContainer{
  width: 340px;
  height: 34px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  padding: 0 0 0 1em;
  background-color: #ffffff;
}

.noVehicles{
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.vehicleList{
  margin-bottom: 24px;
}

.searchBar{
  width: 95%;
}

.searchNote{
  opacity: 0.5;
  margin: 0 auto 0 15px;
}