.footer{
  &Container{
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 12px;
  }
}

.filterContainer{
  display: flex;
  flex-direction: column;
  min-height: 375px;
  justify-content: space-between;
  width: 1100px;
  background: #F2F2F2;
  border-radius: 16px;
  padding: 32px;
  &Top{
    display: flex;
    flex-direction: column;
  }
}

.header{
  font-size: 28px;
  font-weight: 700;
}