.statsContainer {
  background-color: white;
  padding: 32px;
  margin-bottom: 16px;
}

.displayContainer {
  background-color: white;
  padding: 32px;
}

.title {
  font-weight: 700;
  margin-bottom: 32px;
  font-size: 24px;
}

.contentContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: wrap;
  margin: 0 24px;
}

.statBox {
  width: 30%;
  height: 64px;
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.stat {
  font-size: 32px;
  font-weight: 700;
}

.circularContainer {
  width: 124px;
  height: 124px;
  margin-bottom: 16px;
}

.referralBox {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 400px;
  margin-left: 80px;
  margin-right: 80px;
  margin-bottom: 32px;
}
