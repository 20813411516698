@import "../../assets/styles/variables";

.analytics-index {
  min-height: calc(100vh - 40px);
  background-color: $index-background;
  padding: $standard-padding-y $standard-padding-x;
  display: flex;
  flex-direction: column;
}

.loading-plot {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: calc(100vw - 388px);
  height: 650px;
}

.path-head {
  font-size: 14px;
  margin-bottom: 16px;
  font-weight: bold;
}

.analytics-body {
  background-color: white;
  // min-height: calc(100vh - 136px);
  display: flex;
  flex-wrap: wrap;
  padding: 32px;
}

.parameter-panel {
  display: flex;
  width: 100%;
  // justify-content: space-between;
  // max-width: 1450px;
  .param-cell {
    display: flex;
    flex-direction: column;
    width: 200px;
    margin-right: 24px;
    label {
      font-weight: 500;
      margin-bottom: 8px;
    }
  }
  .param-button {
    height: 34px;
    margin-top: auto;
    margin-left: auto;
  }
  .analytic-input-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-top: auto;
    margin-left: auto;
  }
}

.metric-container {
  display: flex;
  padding: 25px 0 25px 100px;
}
.metric-field {
  display: flex;
  flex-direction: column;
  border-left: 1px solid #dedede;
  margin-right: 2em;
  padding: 0 1em;
  color: #414141;
  .metric-value {
    font-size: 48px;
    font-weight: 500;
    span {
      font-size: 24px;
    }
  }
}

.dataset-panel {
  width: 200px;
  display: flex;
  flex-direction: column;
  min-height: calc(100vh - 228px);
  margin: $standard-padding-y 0;
  .dataset-new {
    width: 90%;
    border-radius: 12px;
  }
}

.dataset-display {
  display: flex;
  flex-direction: column;
  width: 90%;
  margin: 1em 0;
  &__header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: $autzu-black;
    margin-bottom: 0.5em;
    .remove-dataset {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;
      width: 18px;
      height: 18px;
      background-color: $autzu-black;
      cursor: pointer;
    }
  }
  &__body {
    border: 2px solid $autzu-black;
    border-radius: 12px;
    line-height: 24px;
    padding: 1em;
    cursor: pointer;
    .flex-container {
      display: flex;
      // align-items: center;
      .icon-container {
        height: 24px;
        width: 18px;
        object-fit: cover;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 4px;
      }
    }
  }
}

.dataset-options {
  width: calc(100% - 4em);
  height: max-content;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  label {
    font-size: 28px;
    margin-bottom: 1em;
    font-weight: 500;
    width: 20%;
  }
  img {
    width: 100px;
    height: 80px;
  }
  &__close {
    position: absolute;
    top: -17px;
    right: -17px;
    width: 32px;
    height: 32px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    border-radius: 50%;
    background-color: $autzu-black;
    color: white;
  }
  &__field {
    width: 100%;
    display: flex;
    // justify-content: space-between;
    padding-bottom: 1em;
    margin-bottom: 1em;
    border-bottom: 1px solid #dedede;
    label {
      font-size: 16px;
      font-weight: 500;
    }
    // &--half {
    //   width: 40%;
    //   display: flex;
    //   flex-direction: column;
    // }
    &--left {
      width: 55%;
    }
    &--right {
      width: 35%;
    }
  }
}

.parking-lot-dropdown {
  display: flex;
  align-items: center;
  :first-child {
    margin-left: -11px;
  }
  .parking-lot-card {
    height: 26px;
    margin-right: 8px;
    width: max-content;
    border-radius: 25px;
    background-color: #e5e5e5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 12px;
    cursor: pointer;
    div {
      margin-right: 10px;
    }
  }
}

.flex-wrap {
  display: flex;
  flex-wrap: wrap;
  max-height: 300px;
  overflow-y: auto;
  .parking-lot-card {
    margin-bottom: 10px;
    margin-right: 8px;
    width: max-content;
    border-radius: 25px;
    background-color: white;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 6px 12px;
    cursor: pointer;
    div {
      margin-right: 10px;
    }
  }
}

.plot-options {
  margin-top: 34px;
  display: flex;
  justify-content: space-between;
  .plot-format-option,
  .plot-axis {
    margin-right: 12px;
    width: 120px;
  }
}

.dataset-city,
.dataset-hub {
  display: flex;
  align-items: center;
}

.analytics-plot {
  width: calc(100vw - 388px);
  max-width: 1250px;
}

.d3-tip {
  line-height: 1;
  font-weight: bold;
  padding: 12px;
  background: $autzu-black;
  opacity: 0.2;
  color: #fff;
  border-radius: 6px;
}

/* Creates a small triangle extender for the tooltip */
.d3-tip:after {
  box-sizing: border-box;
  display: inline;
  font-size: 10px;
  width: 100%;
  line-height: 1;
  color: $autzu-black;
  // opacity: 0.7;
  content: "\25BC";
  position: absolute;
  text-align: center;
}

/* Style northward tooltips differently */
.d3-tip.n:after {
  margin: -1px 0 0 0;
  top: 100%;
  left: 0;
}
