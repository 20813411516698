.management-search-bar {
	left: 2em;
	min-width: 22em;
	min-height: 1em;
	top: 23em;
	background-color: #ffffff;
}

.search-icon {
	padding: 0;
}

.search-input {
	width: 100%;
	color: #414141;
	margin: 0 0 0 1.5em;
}

.filter-title {
	margin-bottom: 8px;
	margin-right: 0.3em;
}
