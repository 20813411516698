.tabsContainer {
  display: flex;
  margin: 0 32px;
}

.tabsContainerSmall {
  display: flex;
}

.tab {
  width: 100%;
  height: 56px;
  display: flex;
  justify-content: center;
  align-items: center;

  font-weight: 500;
  cursor: pointer;
  color: #a0a0a0;
}

.selected {
  opacity: 1;
  background-color: #fff;
  color: #414141;
}
