// dimensions
$taskbar-width: 60px;
$standard-padding-x: 32px;
$standard-padding-y: 20px;
$index-tab-height: 56px;
$button-diameter: 34px;
$column-header-height: 64px;
$user-row-height: 88px;
$user-row-spacing: 12px;

// colours
$autzu-black: #414141;
$autzu-blue: #128dff;
$autzu-green: #3DC700;
$index-background: #f2f2f2;
$index-hover: #dedede;
