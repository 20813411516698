.checkBox {
  font-size: 18px;
  color: #128dff;
  cursor: pointer;
  &Selected {
    font-size: 18px;
  }
}

.allSelected {
  font-size: 18px;
  color: #666666;
  cursor: auto;
}
