.addCategory{
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 52px;
  margin: 32px 0;
  cursor: pointer;
  &Text{
    font-weight: 500;
    font-size: 16px;
    display: flex;
    align-items: center;
  }
}

.marginLeft{
  margin-left: 8px;
}

.bold{
  font-weight: 500;
  line-height: 20px;
}

.icon{
  font-size: 18px;
}
.red{
  color: red;
}

.blue{
  color: #128dff;
}

.actionButton{
  display: flex;
  height: 24px;
  width: 24px;
  background-color: #fff;
  border-radius: 50%;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}