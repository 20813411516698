.categoryTab {
  // border-radius: 8px;
  margin-bottom: 24px;
  padding: 24px;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  min-width: 836px;
}

.categoryTab .tabHeader{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 32px
}

.categoryTab .tabHeader .title{
  display: flex;
  align-items: center;
  font-weight: 700;
  gap: 8px;
}

.categoryTab .tabHeader .title .textComponent{
  display: flex;
  flex-direction: column;
  font-weight: 700;

}


.categoryTab .tabContent {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  // margin-bottom: 32px;
  gap: 32px;
}

.contentBlock {
  display: flex;
  flex-direction: column;
  gap: 4px;
  align-items: center;
  justify-content: space-between;
  min-height: 75px;
}

.contentBlock .statCell{
  display: table-cell;
  vertical-align: middle;
  margin: auto 0;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  }

.contentBlock .footerCell{
  display: flex;
  flex-direction: column;
  align-items: center;
}

.contentBlock .megascript{
  font-size: 32px;
  font-weight: 700;
}

.contentBlock .superscript{
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 10px;
}

.contentBlock .subscript{
  color: #414141;
  font-size: 16px;
}

.contentBlock .miniscript{
  color: #414141;
  font-size: 12px;
}

.contentBlock .miniContainer{
  display: flex;
  flex-direction: row;
}

.contentBlock .miniContainer .miniBlock{
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 8px 16px;
}

.contentBlock .miniContainer .miniBlock .subscript{
  opacity: 0.5;
}

.suspensionReason{
  padding: 5px 14px;
  text-align: center;
  border-radius: 14px;
  background: #414141;
  color: #ffffff;
  font-size: 16px;
  margin: 2px;
}

.circularContainer {
  width: 144px;
  height: 144px;
  margin-bottom: 16px;
  z-index: 1;
}

.circularContainerSeperator {
  background: #414141;
  width: 5px;
  border-radius: 5px;
  height: 8%;
}

.barChartContainer {
  width: 200px;
}

.barChartContainer .barChart {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  width: 100%;
  height: 124px;
}

.barChartContainer .barChart .bar {
  width: 8%;
  background-color: #128dff;
  transition: height 0.3s;
  border-radius: 10px;
}

.barChartContainer .label {
  display: flex;
  justify-content: space-between;
  width: 98%;
  color: #DEDEDE;
  margin-top: 5px;
}

.documentImageDriver {
  border-radius: 20px;
  width: 200px;
  height: 150px;
  margin-bottom: 16px;
  // border: 1px solid #414141;
  display: flex;
  justify-content: space-evenly;
  flex-direction: column;
  object-fit: cover;
}

.documentImageProfile {
  width: 150px;
  height: 150px;
  margin-bottom: 16px;
  border-radius: 50%;
  border: 1px solid #dedede;
  object-fit: cover;
}

.tooltip {
  background-color: #414141;
  color: #ffffff;
  padding: 8px 12px;
  position: absolute;
  transform-origin: top left;
  border-radius: 4px;
  font-size: 14px;
  z-index: 700;
  white-space: nowrap;
  // opacity: 0;
  transition: opacity 0.2s ease;
  pointer-events: none;
  
}

.tooltip .tip {
  position: absolute;
  background-color: #414141;
  transform: rotate(45deg);
  width: 8px;
  height: 8px;
  left: 47%;
  bottom: -8%;
}

.menuContainer {
  position: relative;
  display: inline-block;
}

.menuButton {
  background: transparent;
  border: none;
  cursor: pointer;
  font-size: 24px;
  width: 24px;
  text-align: center;
}

.dropdownMenu {
  position: absolute;
  right: 0;
  background-color: #fff;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  padding: 10px;
  width:220px;
  border-radius: 6px;
  z-index: 1000;
}

.menuItem {
  padding: 8px 20px;
  cursor: pointer;
}

.menuItem:hover {
  background-color: #f6f6f6;
}

.nullItem{
  display: flex;
  padding: 24px 10px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  align-self: stretch;
  opacity: 0.5;
  margin-top: 32px;
}

.popupModalContainer{
  width: 600px;
  height: 244px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}

.popupHead{
  color: #414141;
  font-size: 32px;
  font-weight: 700;
  line-height: normal;
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
}

.popupMessage{
  display: flex;
  justify-content: center;
  font-size: 20px;
  font-weight: 400;
  white-space: pre-wrap;
}

.buttonsFooter {
  justify-content: space-between;
  display: flex;
  width: 100%;
}

.cancelButton {
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  width: 96px;
  padding: 0.36em 1em;
  cursor: pointer;
  opacity: 1;
  color:#414141;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.confirmButton {
  border: none;
  border-radius: 50px;
  font-size: 18px;
  font-weight: 500;
  width: 96px;
  padding: 0.36em 1em;
  cursor: pointer;
  opacity: 1;
  color:#ffffff;
  background-color: #128dff;
  display: flex;
  justify-content: center;
  &:hover {
    transition: 0.25s;
    opacity: 0.9;
  }
  &:active {
    transition: 0.25s;
    transform: scale(0.96);
  }
}

.datescript {
  font-size: 12px;
  font-weight: 400;
  letter-spacing: -0.24px;
}

.statusChangeModal{
  width: 480px;
  height: 282px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
}
