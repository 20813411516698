.paymentHeader {
  padding: 0 32px;
  margin-bottom: 16px;
  background-color: #ffffff;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  min-width: 836px;
  height: 172px;
}

.headerHalfLeft {
  display: flex;
  flex-direction: column;
  padding: 32px 24px 32px 24px;
  width: 700px;
}

.headerHalfRight {
  display: flex;
  flex-direction: column;
  padding: 32px 24px 32px 24px;
  width: 700px;
}

.title {
  font-size: 24px;
  margin-bottom: 6px;
}

.bold {
  font-size: 40px;
  font-weight: 700;
  margin-bottom: 6px;
}

.subtext {
  color: rgba($color: #414141, $alpha: 0.5);
}
