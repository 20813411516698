.hideComponent {
  display: none;
}

.popupContainer {
  position: absolute;
  top: 116px;
  right: 32px;
  width: 182px;
  height: 90px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: white;
  border: 1px solid #dedede;
  z-index: 100;
  padding: 20px;
  border-radius: 16px;
  font-size: 16px;
  box-shadow: 0px 2px 2px #00000052;

  > div {
    font-size: 16px;
    cursor: pointer;
  }
}

.endBooking {
  color: red;
}

.ticketTableContainer {
  margin: -90px 0px 0px -32px;
}

.invoiceTableContainer {
  margin: -56px 0px 0px -32px;
}
