.dropdown {
  display: flex;
  position: absolute;
  flex-direction: column;
  align-items: flex-start;
  text-align: left;
  bottom: 100px;
  // width: 230px;
  background: white;
  border-radius: 25px;
  text-align: center;
  padding: 20px 10px;
  box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.15);
  left: 65px;
  cursor: auto;
}

@media only screen and (max-width: 900px) and (orientation: landscape) {
  .dropdown {
    display: flex;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
    bottom: -125px;
    // width: 230px;
    background: white;
    border-radius: 25px;
    text-align: center;
    padding: 20px 10px;
    box-shadow: 7px 7px 10px rgba(0, 0, 0, 0.15);
    left: 65px;
    cursor: auto;
  }
}

.city {
  font-size: 18px;
  font-weight: 600;
}

.parkingLot {
  margin-left: 10px;
  margin-top: 16px;
}

.hover:hover {
  cursor: pointer;
  color: #2190fb;
}

.triangle {
  position: absolute;
  bottom: 132px;
  left: 58px;
  width: 0;
  height: 0;
  border-right: 0.5em solid white;
  border-top: 0.5em solid transparent;
  border-bottom: 0.5em solid transparent;
  z-index: 1;
}

.textContainer {
  color: #414141;
  text-align: left;
  font-weight: 200;
  &:not(:first-of-type) {
    margin-top: 24px;
  }
}

.dropdownContainer {
  position: relative;
  display: flex;
  justify-content: center;
  // margin-bottom: 30px;
  cursor: pointer;
  color: rgba($color: #ffffff, $alpha: 0.5);
  :hover {
    color: #ffffff;
  }
}

.dropdownContainerOpen {
  position: relative;
  display: flex;
  justify-content: center;
  // margin-bottom: 30px;
  cursor: pointer;
  color: #ffffff;
}

.closed {
  display: none;
}

.selected {
  color: #2190fb;
  font-weight: 600;
}

.reset {
  margin-top: 16px;
  cursor: pointer;
  text-align: left;
}

.save {
  margin-top: 16px;
  cursor: pointer;
  text-align: left;
}

.buttons {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.backdrop {
  display: flex;
  position: absolute;
  align-items: flex-start;
  bottom: 0px;
  width: 250px;
  padding: 36px 24px;
  left: 65px;
  // z-index: 2;
}

.navbarLink {
  font-weight: 400;
  text-align: center;
  width: 60px;
}

.cityContainer {
  margin-left: 23px;
}
