.selections {
  display: flex;
}

.selectionItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 700;
  padding: 0 17px;
  min-width: 120px;
  height: 34px;
  border-radius: 17px;
  background-color: #128dff;
  color: #fff;
  cursor: pointer;
}

.selectionText {
  margin-right: 16px;
  font-weight: 700;
  line-height: 16px;
}

.orText {
  margin: 0 8px;
  font-weight: 700;
}

.filtersSelectionGroup {
  display: flex;
  align-items: center;
  margin: 0 32px;
}

.text {
  width: 100%;
  display: flex;
  justify-content: center;
}

.confirmModal {
  display: flex;
  padding: 32px;
  width: 536px;
  height: 91px;
  flex-direction: column;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 16px;
}

.confirmFooter {
  display: flex;
  width: 100%;
}

.oneButton{
  justify-content: space-around;
}

.twoButtons{
  justify-content: space-between;
}

.confirmFooterCancel {
  justify-content: center;
  display: flex;
  width: 100%;
}
