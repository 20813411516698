.absoluteContainer {
  position: absolute;
  right: 0;
  top: 32px;
}

.hidden {
  display: none;
}
.optionsBox {
  // height: 532px;
  min-width: 532px;
  margin: 16px 0 1px;
  background: #fff 0 0 no-repeat padding-box;
  padding: 16px;
  border-radius: 16px;
  font-size: 16px;
  box-shadow: 0 2px 2px #00000052;
}

.headerBox {
  z-index: 30;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.header {
  width: 100%;
  text-align: left;
  font-weight: 700;
  font-size: 28px;
  color: #414141;
  margin-bottom: 16px;
}

.closeIcon {
  color: #414141;
  font-size: 24px;
}

.footer {
  &Container {
    height: 40px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 12px;
  }
}
