.inspectionContainer {
  display: flex;
  width: 480px;
  padding: 16px;
  flex-direction: column;
  border-radius: 16px;
  background-color: #ffffff;
}

.inspectionTitle {
  font-size: 24px;
  font-weight: 700;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}

.inspectionField {
  display: flex;
  flex-direction: column;
  margin-bottom: 16px;
}

.subscript {
  font-size: 12px;
  font-weight: 400;
  margin-bottom: 8px;
}

.imgContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.exteriorImg {
  object-fit: cover;
  border-radius: 20px;
  width: 80px;
  height: 80px;
  background-color: #dedede;
  border: none;
  cursor: pointer;
  margin-bottom: 8px;
}

.toClick {
  cursor: pointer;
}

.safetyConditionGood {
  font-weight: 700;
  color: #27ae60;
  margin-bottom: 16px;
}

.safetyConditionBad {
  color: red;
  display: flex;
  flex-direction: row;
  font-weight: 700;
  margin-bottom: 16px;
}

.badSafety {
  display: flex;
  flex-direction: column;
}

.cleanComment {
  display: flex;
  flex-direction: column;
}

.closeImg {
  border-color: red;
  background-color: red;
  color: white;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  left: 580px;
  top: -10px;
  font-size: 20px;
  cursor: pointer;
}

.fullImageContainer {
  display: flex;
  flex-direction: row;
  position: absolute;
}

.nextButton {
  color: #dedede;
  opacity: 0.5;
  position: absolute;
  z-index: 10;
  left: 549px;
  top: 300px;
  font-size: 32px;
  cursor: pointer;
}

.prevButton {
  color: #dedede;
  opacity: 0.5;
  position: absolute;
  z-index: 10;
  left: 20px;
  top: 300px;
  font-size: 32px;
  cursor: pointer;
}

.nextButton :hover {
  color: #128dff;
}

.prevButton :hover {
  color: #128dff;
}

.fullImageDisplay {
  width: 600px;
  height: 700px;
}
