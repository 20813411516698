.layout-background {
  display: flex;
  align-items: center;
  justify-content: space-around;
  background-color: #414141;
}
@media (max-width: 767px) {
  .layout-background {
    justify-content: center;
    overflow: auto;
    flex-direction: column;
  }
}

.x-icon {
  cursor: pointer;
  transform: translateY(5vh);
  margin-left: 50px;
  height: 25px;
}
@media (max-width: 767px) {
  .x-icon {
    margin-left: 15px;
  }
}

.layout-section {
  background-color: #242424;
  display: flex;
  flex-direction: column;
}

.autzu-text {
  color: #e0e0e0;
  font-size: 6.5rem;
  font-weight: 400;
}
.autzu-text:hover {
  color: white;
}

.layout-form {
  display: flex;
  flex-direction: column;
  text-align: center;
}
@media (max-width: 767px) {
  .layout-form {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
}

.sign-up-x {
  color: #e0e0e0;
  cursor: pointer;
  position: absolute;
  padding: 0.5em;
  margin: 0;
}
.sign-up-x:hover {
  color: white;
}

.white-logo {
  height: 100px;
}

.watch-video {
  color: white;
  cursor: pointer;
  transform: translateY(-12vh);
  margin-left: 50px;
}
@media (max-width: 767px) {
  .watch-video {
    transform: translateY(0vh);
    margin-left: 0px;
    text-align: center;
  }
}
.watch-video:hover {
  color: #e0e0e0;
}
.watch-video .watch-video-copy {
  font-weight: 100;
}

.play-icon {
  margin-right: 5px;
}
