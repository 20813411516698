.carsContainer {
  &__top {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }
}

.carsAvailableTitle {
  text-align: center;
  margin: 0 0 1em 0;
}

.dayContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 24px 24px 0;
  .daySingle {
    width: 80%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 1em;

    &__today {
      background-color: #96e6fa;
    }

    .date {
      // width: 100%;
    }
    .singleShift {
      width: 30%;
    }
  }

  @media (min-width: 813px) {
    flex-direction: row;
    justify-content: flex-start;
    width: 100%;
    .daySingle {
      margin: auto 5px;
      display: block;
      .date {
        // width: 100%;
      }
      .singleShift {
        width: 100%;
      }
    }
  }
}

.singleShift {
  height: 100%;
  margin-top: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  border-radius: 4px;
  display: flex;
  flex-direction: row;
}

.shiftTime {
  border-radius: 4px 4px 0 0;
}

.carList {
  // border-radius: 0 0 4px 4px;
  height: 10vh;
  display: flex;
  overflow-y: scroll;
  flex-direction: column;
  align-items: center;

  &__red {
    color: #fb7272;
  }

  &__orange {
    color: #f5a522;
  }

  &.center {
    font-size: 2em;
    font-weight: 800;
    justify-content: center;
    overflow-y: auto;
  }
}

.date {
  text-align: center;
  padding-bottom: 5px;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  margin-top: -55px;
}

.shiftTime {
  text-align: center;
  background-color: #424242;
  color: #f5f5f5;
  padding-top: 5px;
  padding-bottom: 5px;
  @media (max-width: 812px) {
    font-size: 0.8em;
  }
  p {
    margin: 0;
  }
}

.tierSelection {
  display: flex;
  justify-content: center;
}

.switch {
  padding: 10px 30px;
  transition: 0.5s;
}

.carsAvailable {
  &__switch {
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    padding: 10px 30px;
    transition: 0.5s;

    @media (min-width: 813px) {
      padding: 10px 30px;
    }

    &:hover {
      background-color: #eee;
      border-color: none;
      cursor: pointer;
    }

    &-nav {
      border-radius: 4px;
    }

    &-left {
      border-radius: 4px 0 0 4px;
    }

    &-right {
      border-radius: 0 4px 4px 0;
    }

    &-active,
    &:active,
    &:focus,
    &.focus {
      background-color: #242424;
      color: #f5f5f5;
      border-color: none;

      &:hover {
        background-color: #444;
        color: #f5f5f5;
        border-color: none;
      }
    }
  }
}

.avaPage {
  padding-top: 20px;
}

.avaCanvas {
  background-color: #ffffff;
  margin-left: 32px;
  margin-right: 32px;
  margin-top: 24px;
}

.avaFilter {
  padding: 24px;
  border-bottom: 1px solid #dedede;
  margin-bottom: 32px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.avaDropdown {
  display: flex;
  flex-direction: column;
  margin-right: 24px;
}

.dropdowns {
  display: flex;
  flex-direction: row;
}

.singleCell {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
  cursor: pointer;
}

.timesContainer {
  display: flex;
  flex-direction: column;
  margin-left: 24px;
  margin-top: 4px;
}

.timeZone {
  font-size: 16px;
  margin-bottom: 10px;
  height: 16px;
  color: #414141;
  text-align: center;
  align-items: center;
  font-weight: 700;
}

.timeSlot {
  width: 60px;
  height: 60px;
  border-radius: 8px;
  background-color: #dedede;
  color: #414141;
  text-align: center;
  align-items: center;
  display: flex;
  margin-bottom: 10px;
}

.carCount {
  width: 10vw;
  height: 60px;
  border-radius: 4px;
  // background-color: #128dff;
  color: #f2f2f2;
  margin: 0 0.5vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.singleDay {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.dateToday {
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 30px;
  margin-top: -64px;
  width: 65px;
  height: 65px;
  z-index: 10;
  border-radius: 50%;
  background-color: #dedede;
}

.monthTitle {
  margin: 0 auto 32px;
  width: fit-content;
  font-size: 28px;
  font-weight: 700;
}

.cellInfoContainer {
  display: flex;
  flex-direction: column;
  height: 530px;
  width: 480px;
  background: #f2f2f2;
  border-radius: 16px;
  padding: 32px;
}

.infocardTitle {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 24px;
}

.infocardField {
  display: flex;
  flex-direction: column;
  margin-bottom: 24px;
}

.cellButton {
  align-self: center;
  border: none;
  border-radius: 20px;
  background-color: #ffffff;
  width: 106px;
  height: 36px;
  padding: 8px 10px;
  color: #414141;
  font-weight: 700;
  margin-top: 45px;
  cursor: pointer;
}

.dropdownTitle {
  font-size: 12px;
}
