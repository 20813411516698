.footer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 32px 0;
}
.paginationContainer{
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-bottom: 12px;
}

.previousPage, .nextPage{
  cursor: pointer;
  color: #414141;

  &Icon{
    margin: 0 6px;
  }
}

.disabled{
  opacity: 0.5;
}

.pageNumber {
  margin: 0 6px;
  cursor: pointer;
  color: #414141;
  opacity: 0.5;
}
.selected {
  opacity: 1;
}