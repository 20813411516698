.actionBar {
  display: flex;
  justify-content: space-between;
  margin: 16px 32px 24px;
}

.actionBarSmall {
  //FIND BETTER UNIVERSAL SOLUTION!! THIS IS A BANDAID
  display: flex;
  justify-content: space-between;
  margin: 0px -16px 8px 0px;
}

.leftGroup,
.rightGroup {
  display: flex;
}

.toggleTicketsButtonContainer {
  display: flex;
  flex-direction: row;
  margin-right: 32px;
  align-items: center;
}

.ticketButtons {
  display: flex;
  flex-direction: row;
  align-items: center;
}
