@import "../../../../assets/styles/variables";

// .denyPopup {
//   width: 620px;
//   border: 1px solid #dedede;
//   border-radius: 16px;
//   background-color: #f2f2f2;
//   padding: 32px;
//   display: flex;
//   flex-direction: column;
// }

.denyPopup {
  width: 546px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  display: flex;
  flex-direction: column;
  text-align: center;
}

.denyReason {
  padding: 0.7em;
  resize: none;
  height: 108px;
  border: 1px solid #dedede;
  border-radius: 8px;
  font-size: 16px;
  margin-top: 32px;
}

.cancelButton {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #414141;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.actionButtons {
  margin-top: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.saveButton {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #128dff;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.saveButtonDisabled {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #87cefa;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.notSaveButton {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #87cefa;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.arrangePopup {
  width: 536px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  padding: 32px;
  display: flex;
  flex-direction: column;
}

.arrangeTitle {
  margin-bottom: 32px;
  font-size: 24px;
  font-weight: 700;
}

.plainText {
  font-size: 16px;
}

.bold {
  font-weight: 700;
}

.dropdownText {
  margin-top: 12px;
  display: flex;
  flex-direction: row;
}

.financeInfo {
  padding-bottom: 28px;
  border-bottom: solid 2px #dedede;
}

.installmentContainer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 214px;
  margin-top: 16px;
}

.dateInput {
  background: white;
  display: flex;
  align-items: center;
  color: #414141;
  width: 125px;
  border: 1px solid #414141;
  border-radius: 10px;
  padding: 0 8px;
  font-weight: 400;
  font-size: 15px;
  height: 24px;
}

.elementContainer {
  width: 252px;
  justify-content: space-between;
  display: flex;
  flex-direction: row;
  margin-right: 12px;
  margin-bottom: 8px;
}

.errorModal {
  display: flex;
  padding: 32px;
  width: 536px;
  height: 91px;
  flex-direction: column;
  justify-content: space-between;
  background: #f2f2f2;
  border-radius: 16px;
}

.text {
  width: 100%;
  display: flex;
  justify-content: center;
}

.errorCancel {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.errorCancelButton {
  height: 40px;
  width: 152px;
  background-color: #ffffff;
  color: #414141;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  font-weight: 700;
  font-size: 16px;
}
