.searchBar {
  width: 95%;
  border-radius: 25px;
}

.searchContainer {
  width: 360px;
  height: 34px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  // margin: 0 0 0 1em;
  padding: 0 0 0 17px;
  background-color: #fff;
}

.searchContainerWhiteBack {
  width: 360px;
  height: 34px;
  border-radius: 25px;
  display: flex;
  align-items: center;
  margin: 0 0 0 1em;
  padding: 0 0 0 17px;
  background-color: #fff;
  border: 1px solid #414141;
}
