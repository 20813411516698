.breakWord {
  word-wrap: break-word;
  font-weight: 700;
  font-size: 14px;
}

.center {
  text-align: center;
}

.linkContainer {
  display: block;
  text-align: -webkit-center;
}

.fleetContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.sortableIcon {
  margin-left: 8px;
}

.sortable {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  text-align: center;
}

.sortableLeft {
  display: flex;
  align-items: center;
  font-size: 16px;
}

.link {
  display: flex;
  justify-content: center;
  text-decoration: none;
  color: black;
  margin-right: 32px;
  max-width: 1575px;
  /* border-bottom: 1px solid #d3d3d3;*/
}

.vehicleData {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  &__overdue {
    font-size: 16px;
    font-weight: 800;
    text-align: center;
  }
}
.fleetGrey {
  color: #999999;
  font-size: 62.5%;
  text-align: center;
  &__overdue {
    font-size: 62.5%;
    text-align: center;
  }
}
.fleetGreyService {
  color: #999999;
  font-size: 62.5%;
  text-align: left;
  margin: 5px 0 5px 0;
  &__overdue {
    font-size: 62.5%;
    text-align: left;
  }
}

.fleetGreyService {
  color: #999999;
  font-size: 62.5%;
  text-align: left;
  margin: 5px 0 5px 0;
  &__overdue {
    font-size: 62.5%;
    text-align: left;
  }
}

.locationLink {
  color: #128dff;
  text-decoration: none;
  &__overdue {
    text-align: center;
    color: #ffffff;
  }
}

/*.statusBox{
  display: flex;
  justify-content: center;
}*/

.subtext {
  font-size: 10px;
  color: #a0a0a0;
}

.profileText {
  font-size: 15px;
  color: #414141;
  text-align: center;
}

.columnName {
  font-weight: 700;
  line-height: 16px;
}

.imageValue {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image {
  max-width: 111px;
  max-height: 46px;
}

.userImageContainer {
  width: 44px;
  height: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  overflow: hidden;
  border: solid 1px #dedede;
}

.carImage {
  max-width: 111px;
  max-height: 46px;
}
.vehicleStatus {
  border-radius: 50%;
  width: 12px;
  height: 12px;
  margin-right: 0.4em;
}
.statusLegend {
  display: flex;
  align-items: center;
  justify-content: left;
  padding-top: 5px;
}

.vehicleContent {
  width: fit-content;
  text-align: left;
  margin: auto;
  min-width: 70px;
}
.serviceContent {
  font-size: 14px;
  text-align: left;
}
.overdue {
  background-color: none;
  background-color: #c90000;
  color: #ffffff;
  // animation: blinker 1.5s cubic-bezier(0.5, 0, 1, 1) infinite;
}

.serviceCount {
  font-size: 12px;
  color: #128dff;
  width: 100%;
  text-align: right;
  &__overdue {
    color: #ffffff;
    font-size: 12px;
    width: 100%;
    text-align: right;
  }
}

.editUnder {
  width: 80%;
  height: 1px;

  background-color: #414141;
  margin: 2px auto 0px auto;
  &__overdue {
    width: 80%;

    height: 1px;
    background-color: #ffffff;
    margin: 2px auto 0px auto;
  }
}

.addTagContainer {
  display: flex;
  justify-content: left;
  align-items: center;
  margin: 0px 0 24px 0;
}

.displayTags {
  display: flex;
}

.existingTags {
  display: flex;
  flex-direction: column;
  cursor: pointer;
  height: 115px;

  flex-wrap: wrap;
  overflow-x: scroll;
}

.vehicleTags {
  height: 115px;
  min-width: 200px;
  overflow-y: scroll;
}

.tagsHeader {
  display: flex;
  font-size: 17.5px;
  font-weight: bold;
  margin: 0px 0 16px 0;
}

.tagBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: fit-content;
  height: auto;
  min-height: 24px;
  border-radius: 25px;
  border: 1px solid #414141;
  font-size: 17px;
  padding: 0 12px 0 12px;
  margin-bottom: 8px;
}

.tagName {
  display: inline-block;
  height: auto;
  overflow-x: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.tagName:hover {
  white-space: pre-line;
}

.tagRemove {
  margin-left: 12px;
}

.tagSaveButtons {
  position: absolute;
  margin: 21px 0 21px 0;
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.serviceTimes {
  width: 60%;
  margin: auto;
}

.serviceDays {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 32px;
}
.overdueDays {
  height: 100%;
}

.circle {
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
  margin-top: 2px;
}

.green {
  background-color: #3dc700;
}

.orange {
  background-color: #ff8900;
}

.red {
  background-color: #ff0000;
}

.grey {
  background-color: #414141;
}

.obdStatus {
  font-size: 14px;
  text-align: left;
  &Container {
    display: flex;
    align-items: baseline;
    width: 80%;
    margin: auto;
  }
}

@keyframes blinker {
  50% {
    background-color: #ff000040;
  }
}

@media (min-width: 768px) {
  .vehicleData {
    font-size: 12.5px;
    &__overdue {
      font-size: 12.5px;
    }
  }
  .serviceContent {
    font-size: 12.5px;
  }
}

@media (min-width: 1024px) {
  .vehicleData {
    font-size: 14px;
    &__overdue {
      font-size: 14px;
    }
  }
  .serviceContent {
    font-size: 14px;
  }

  .fleetGrey {
    color: #999999;
    font-size: 62.5%;
    text-align: center;
    &__overdue {
      font-size: 62.5%;
      text-align: center;
    }
  }
  .editUnder {
    width: 90%;
    height: 1px;

    background-color: #414141;
    margin: 2px auto 0px auto;
    &__overdue {
      width: 90%;

      height: 1px;
      background-color: #ffffff;
      margin: 2px auto 0px auto;
    }
  }
}

.passwordCheck {
  width: 600px;
  height: 120px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.passwordTitle {
  font-weight: 600;
  font-size: 24px;
  margin-bottom: 12px;
}

.confirmCheck {
  width: 500px;
  height: 100px;
  padding: 32px;
  border: 1px solid #dedede;
  border-radius: 16px;
  background-color: #f2f2f2;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
}

.actionButtons {
  margin-top: 20px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.cancelButton {
  height: 36px;
  width: 136px;
  background-color: #ffffff;
  color: #414141;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.notSaveButton {
  height: 36px;
  width: 136px;
  background-color: #ffffff;
  color: #87cefa;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}

.saveButton {
  height: 36px;
  width: 136px;
  background-color: #ffffff;
  color: #128dff;
  border-radius: 20px;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  padding: 7px 16px 7px 16px;
  margin-left: auto;
  font-weight: 700;
  font-size: 16px;
}
//hover = #930000
//70% white

.text {
  width: 100%;
  display: flex;
  justify-content: center;
}
